import MenuContainer_ResponsiveComponent from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/skinComps/Responsive/Responsive.skin';
import MenuContainer_ResponsiveController from '@wix/thunderbolt-elements/src/components/MenuContainer/viewer/MenuContainer.controller';


const MenuContainer_Responsive = {
  component: MenuContainer_ResponsiveComponent,
  controller: MenuContainer_ResponsiveController
};


export const components = {
  ['MenuContainer_Responsive']: MenuContainer_Responsive
};

